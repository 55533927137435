var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "550px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050P150.001"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P150.002")))]),
          _c("table", { staticClass: "tbl_col mt5" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("No")]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G050.007")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P150.003")))]),
                _c("th", [_vm._v("SHPR CFM")]),
                _c("th", [_vm._v("CFM")]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P150.004")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ADMN030G010.009")))]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.blErrorList, function (vo, idx) {
                return _c("tr", { key: "err_" + idx }, [
                  _c("td", [_vm._v(_vm._s(vo.num))]),
                  _c("td", [_vm._v(_vm._s(vo.rstcItemNm))]),
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(_vm._s(vo.errMsg)),
                  ]),
                  _c("td", [
                    _vm.webNuseYn !== "Y" && vo.shprCfmNuseYn !== "Y"
                      ? _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.shprCfmYn,
                                  expression: "vo.shprCfmYn",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    vo,
                                    "shprCfmYn",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "Y" } }, [
                                _vm._v("Y"),
                              ]),
                              _c("option", { attrs: { value: "N" } }, [
                                _vm._v("N"),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("td", [_vm._v(_vm._s(vo.cfmYn))]),
                  _c("td", [_vm._v(_vm._s(vo.rspsCont))]),
                  _c("td", [
                    _vm.$ekmtcCommon.isNotEmpty(vo.lnkUrlAddr)
                      ? _c(
                          "a",
                          {
                            staticClass: "link a_cursor",
                            on: {
                              click: function ($event) {
                                return _vm.clickLink(vo.lnkUrlAddr)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX050P150.005")))]
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c("p", { staticClass: "txt_desc" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.ONEX050P150.006"))),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.$t("msg.ONEX050P150.007")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _vm.webNuseYn !== "Y"
            ? _c(
                "a",
                {
                  staticClass: "button blue lg",
                  on: {
                    click: function ($event) {
                      return _vm.fnUpdateBlError()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.024")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "18%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }