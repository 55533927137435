<template>
  <div class="popup_wrap" style="width:900px; height:550px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONEX050P150.001') }}</h1>
      <div class="content_box">
        <p>{{ $t('msg.ONEX050P150.002') }}</p>
        <table class="tbl_col mt5">
          <colgroup>
            <col width="6%">
            <col width="20%">
            <col width="30%">
            <col width="10%">
            <col width="6%">
            <col width="18%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>{{ $t('msg.CSCT060G050.007') }}</th>
              <th>{{ $t('msg.ONEX050P150.003') }}</th>
              <th>SHPR CFM</th>
              <th>CFM</th>
              <th>{{ $t('msg.ONEX050P150.004') }}</th>
              <th>{{ $t('msg.ADMN030G010.009') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in blErrorList" :key="'err_' + idx">
              <td>{{ vo.num }}</td>
              <td>{{ vo.rstcItemNm }}</td>
              <td class="text_left">{{ vo.errMsg }}</td>
              <td>
                <div v-if="webNuseYn !== 'Y' && vo.shprCfmNuseYn !== 'Y'">
                  <select
                    v-model="vo.shprCfmYn"
                  >
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </div>
              </td>
              <td>{{ vo.cfmYn }}</td>
              <td>{{ vo.rspsCont }}</td>
              <td>
                <a v-if="$ekmtcCommon.isNotEmpty(vo.lnkUrlAddr)" class="link a_cursor" @click="clickLink(vo.lnkUrlAddr)">{{ $t('msg.ONEX050P150.005') }}</a>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <h2 class="content_title">Remark</h2>
        <div>
          <textarea v-model="reqRsnCont" readonly></textarea>
        </div> -->
        <p class="txt_desc">
          {{ $t('msg.ONEX050P150.006') }}<br>
          {{ $t('msg.ONEX050P150.007') }}
        </p>
      </div><!-- content_box // -->

      <div class="mt10 text_center">
        <a v-if="webNuseYn !== 'Y'" class="button blue lg" @click="fnUpdateBlError()">{{ $t('msg.CSBK100.178') }}</a>
        <a class="button gray lg" @click="$emit('close')">{{ $t('msg.CSBK100.024') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import trans from '@/api/rest/trans/trans'

export default {
  name: 'BLErrorCheckPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          blNo: '',
          kindCd: '',
          catCd: ''
        }
      }
    }
  },
  data () {
    return {
      blErrorList: [],
      webNuseYn: 'Y',
      reqRsnCont: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = { ...this.parentInfo }
      trans.findBLErrorCheck(params).then(res => {
        if (res.headers.respcode === 'C0000') {
          const result = res.data
          const blErrorList = result.blErrorList
          this.blErrorList = blErrorList
          this.webNuseYn = result.webNuseYn

          /* if (blErrorList !== undefined && blErrorList.length > 0) {
            const apvCont = blErrorList[blErrorList.length - 1].apvCont
            this.reqRsnCont = apvCont
          } */
        }
      })
    },
    async fnUpdateBlError () {
      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT022'), useConfirmBtn: true })) {
        return
      }

      const regParams = {
        blNo: this.parentInfo.blNo,
        blErrorList: this.blErrorList
      }

      let succFlag = true
      let result = null
      await trans.updateErrorCheck(regParams).then(res => {
        if (res.headers.respcode === 'C0000') {
          result = res.data

          if (result !== 'SUCC') {
            succFlag = false
          }
        } else {
          succFlag = false
        }
      }).catch(() => {
        succFlag = false
      }).finally(() => {
        if (succFlag) {
          const TH = this
          this.$ekmtcCommon.alertCallback(this.$t('tem.CMAT015'), () => {
            TH.$emit('close')
          })
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
        }
      })
    },
    clickLink (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
  .a_cursor {cursor: pointer;}
</style>
